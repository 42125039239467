import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup,Validators } from '@angular/forms';
import { Router } from '@angular/router';

//import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import * as DateValidator from '../_files/date.validator';
//import { AbstractControl } from "@angular/forms";
import * as moment from 'moment';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  Fname: string = "";
  public fName: string;
  public codeValue: string;
  //public firstName:AbstractControl;



  constructor(private router: Router,private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.fName='test1';
    this.codeValue='test3'
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      designation: ['', Validators.required],
      mobileno: ['', Validators.required],
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      //expectedDate: ['', Validators.compose([Validators.required, this.dateVaidator])],
      verifyOTP:['', Validators.required],
      prefferduserid:['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmpassword: ['', [Validators.required, Validators.minLength(6)]]
  });
  this.registerForm = this.formBuilder.group({
    firstName: '',
    lastName: '',
    designation: '',
    mobileno: '',
    email: '',
    //expectedDate: ['', Validators.compose([Validators.required, this.dateVaidator])],
    verifyOTP:'',
    prefferduserid:'',
    password: '',
    confirmpassword: ''
  });


  }

  // dateVaidator(AC: AbstractControl) {
  //   if (AC && AC.value && !moment(AC.value, "DD/MM/YYYY", true).isValid()) {
  //     return { dateVaidator: true };
  //   }
  //   return null;
  // }
  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {

    this.Fname=this.registerForm.get('firstName')?.value;
    alert(this.registerForm.get('firstName')?.value)
      this.submitted = true;

      // stop here if form is invalid
      if (this.registerForm.invalid) {
          return;
      }

      alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value))
  }

  login()
  {
    this.router.navigateByUrl('/login')
  }

}




