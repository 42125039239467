import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.html',
  styleUrls: ['./logout.css']
})
export class Logout implements OnInit {
  title = 'logout';
  email = 'string';

  constructor() { }

  ngOnInit(): void {
  }

}
