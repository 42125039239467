// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  api_key : '',
  enc_iv : '',
  enc_key : '',
 // API_URL : 'http://123.108.50.71:8080/acqpass/',
  API_URL : 'http://192.168.0.23/api/',
 // API_URL : 'http://localhost/api/',

  GetStateList_URL: 'api/getstate',
//API_URL : 'http://nexmoney.xyz/api/',
  Login_URL : 'Login',
   Register_URL : 'Register/Registration',
   CheckUserName_Url: 'Register/checkUserName/',
   Logout_URL : 'logout',
   GetSupplierCat_URL: 'OTP/verifyOTP',
   ResendOtp_Url : 'OTP/resendOTP/',
   RegOtp_Url : 'OTP/sendRegisterOTP/',
   RegVerifyOtp_URL: 'OTP/regverifyOTP',
   GetCountry_URL:'LookupValue',
   GetTitle_URL:'LookupValue',
   GetOrganizationType_URL:'LookupValue',
   GetRegion_URL:'LookupValue',
   GetBankCountry_URL:'LookupValue',
   GetCurrency_URL:'LookupValue',
   GetAccountType_URL:'LookupValue',
   GetMinorityIndicator_URL:'LookupValue'
};
