import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-purforprocurement',
  templateUrl: './purforprocurement.component.html',
  styleUrls: ['./purforprocurement.component.css']
})
export class PurforprocurementComponent implements OnInit {

  constructor(private router: Router) { }
  public PurchaseforProcurementData:any;
  ngOnInit() {

    this.PurchaseforProcurementData = [
      {
        "RequisitionsNo": "1",
        "MaterialDescription": "Pen",
        "MaterialSubDescription1": "Gail Pen",
        "MaterialSubDescription2": "Ink Pen",
        "MaterialCategory": "Stationery",
        "UOM": "Num",
        "Quantity": "10",
        "ProductCategory": "Stationery"
       
       
       
        
      },

      {
        "RequisitionsNo": "2",
        "MaterialDescription": "Laptop",
        "MaterialSubDescription1": "Touch Pad",
        "MaterialSubDescription2": "Dell",
        "MaterialCategory": "IT",
        "UOM": "Num",
        "Quantity": "10",
        "ProductCategory": "IT"      
        
        
      
        
      }
    ]
  }

  GeneratePO()
  {
    this.router.navigateByUrl('/po')

  }

  
  RFQ()
  {
    this.router.navigateByUrl('/rfq')

  }

}
