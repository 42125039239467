import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

// import { MatSlideToggleModule } from '@angular/material/slide-toggle';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';
//import { Registration } from './registration/registration';

import { Quotation } from './quotation/quotation';

import { Login } from './login/login';
import { Logout } from './logout/logout';
import { TabsComponent } from './tabs/tabs.component';
import { InfoComponent } from './info/info.component';


import { DashboardComponent } from './dashboard/dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { TableListComponent } from './table-list/table-list.component';
import { TypographyComponent } from './typography/typography.component';
import { IconsComponent } from './icons/icons.component';
import { MapsComponent } from './maps/maps.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { UpgradeComponent } from './upgrade/upgrade.component';

import { AgmCoreModule } from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

//import{Signup} from './signup/signup';

import { CustomScrollDirective } from '../app/registration/custom-scroll.directive';
import { EmpReqComponent } from './emp-req/emp-req.component';
import { StockkeeperComponent } from './stockkeeper/stockkeeper.component';
import { PurforprocurementComponent } from './purforprocurement/purforprocurement.component';
import { RfqComponent } from './rfq/rfq.component';
import { PoComponent } from './po/po.component';
import { ReqstatusComponent } from './reqstatus/reqstatus.component';
import { VendorstatusComponent } from './vendorstatus/vendorstatus.component';

import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatRippleModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';

import { Dispatch } from './dispatch/dispatch';
import { Invoiceupload } from './invoiceupload/invoiceupload';
import { Purchaseorder } from './purchaseorder/purchaseorder';
import { Paymentstatus } from './paymentstatus/paymentstatus';
import { RegComponent } from './reg/reg.component';
import { SignupComponent } from './signup/signup.component';




@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatTabsModule,
  
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    })
  ],
  declarations: [
    //Signup,
    AppComponent,
    AdminLayoutComponent,
    CustomScrollDirective,
    EmpReqComponent,
    StockkeeperComponent,
    PurforprocurementComponent,
    RfqComponent,
    PoComponent,
    ReqstatusComponent,
    VendorstatusComponent,
    Login,
    Logout,
    DashboardComponent,
    //Registration,
    Quotation,
    Dispatch,
    Invoiceupload,
    Purchaseorder,
    Paymentstatus,
    RegComponent,
    SignupComponent
    
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
