import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

//import { Signup } from './signup/signup';

import { LandingComponent } from '../app/landing/landing.component';
import { Login } from '../app/login/login';
import{ForgotPassComponent} from '../app/forgot-pass/forgot-pass.component';
import{ResetComponent} from '../app/reset/reset.component';
import{SendotpComponent} from '../app/sendotp/sendotp.component';
//import { RegisterComponent } from '../app/register/register.component';
import { DashboardComponent } from '../app/dashboard/dashboard.component';
//import { Registration } from '../app/registration/registration';
import { Quotation } from './quotation/quotation';
import { Dispatch } from './dispatch/dispatch';
import { Invoiceupload } from './invoiceupload/invoiceupload';
import { Purchaseorder } from './purchaseorder/purchaseorder';
import { Paymentstatus } from './paymentstatus/paymentstatus';
import { Logout } from './logout/logout';
import {EmpReqComponent} from './emp-req/emp-req.component';
import {StockkeeperComponent} from './stockkeeper/stockkeeper.component';
import {PurforprocurementComponent} from './purforprocurement/purforprocurement.component';
import {RfqComponent} from './rfq/rfq.component';
import {PoComponent} from './po/po.component';

import { ReqstatusComponent } from './reqstatus/reqstatus.component';
import { VendorstatusComponent } from './vendorstatus/vendorstatus.component';

import { RegComponent } from './reg/reg.component';
import { SignupComponent } from './signup/signup.component';


const routes: Routes =[

  {path:'',component:LandingComponent},
  {
    path: '',
    redirectTo: 'signup',
    pathMatch: 'full',
  },
  
  { path: 'login',   component: Login },
  { path: 'forgot-pass',   component: ForgotPassComponent },
  { path: 'reset',   component: ResetComponent },
  { path: 'sendotp',   component: SendotpComponent },
  //{ path: 'register',   component: RegisterComponent },
  { path: 'signup',   component: SignupComponent },
  
   {
    path: '',
    component: AdminLayoutComponent,
    children: [{
      path: 'layouts',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
    },
    { path: 'reg', component: RegComponent },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'quotation', component: Quotation },
    { path: 'dispatch',   component: Dispatch },
    { path: 'invoiceupload',   component: Invoiceupload },  
    { path: 'purchaseorder',   component: Purchaseorder }, 
    { path: 'paymentstatus',   component: Paymentstatus }, 
    { path: 'login',   component: Logout },
    {path:'emp-req',component:EmpReqComponent},
    {path:'stockkeeper',component:StockkeeperComponent},
    {path:'purforprocurement',component:PurforprocurementComponent},
    {path:'rfq',component:RfqComponent},
    {path:'po',component:PoComponent},
    {path:'reqstatus',component:ReqstatusComponent},
    {path:'vendorstatus',component:VendorstatusComponent},
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
       useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }