import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Component, OnInit } from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import * as Chartist from 'chartist';


@Component({
  selector: 'app-invoiceupload',
  templateUrl: './invoiceupload.html',
  styleUrls: ['./invoiceupload.css']
})
export class Invoiceupload implements OnInit {
  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;


    
  constructor() { }
  ngOnInit() {  
  }
}
