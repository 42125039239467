import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rfq',
  templateUrl: './rfq.component.html',
  styleUrls: ['./rfq.component.css']
})
export class RfqComponent implements OnInit {

  constructor() { }
  public RFQData:any;
  ngOnInit(): void {
    this.RFQData = [
      {
        "RequisitionsNo": "1",
        "Vendor":"ESMPL PVT. LTD.",
        "MaterialDescription": "Pen",
        "MaterialSubDescription1": "Gail Pen",
        "MaterialSubDescription2": "Ink Pen",
        "MaterialCategory": "Stationery",
        "UOM": "Num",
        "Quantity": "10"     
       
       
       
        
      },

      {
        "RequisitionsNo": "2",
        "Vendor":"Amol PVT. LTD.",
        "MaterialDescription": "Laptop",
        "MaterialSubDescription1": "Touch Pad",
        "MaterialSubDescription2": "Dell",
        "MaterialCategory": "IT",
        "UOM": "Num",
        "Quantity": "10"  
        
        
      
        
      }
    ]
  }

}
