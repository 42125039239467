import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stockkeeper',
  templateUrl: './stockkeeper.component.html',
  styleUrls: ['./stockkeeper.component.css']
})
export class StockkeeperComponent implements OnInit {

  constructor() { }
  public StockKeeperData:any;
  ngOnInit() {

    this.StockKeeperData = [
      {
        "RequisitionsNo": "1",
        "MaterialDescription": "Pen",
        "MaterialSubDescription1": "Gail Pen",
        "MaterialSubDescription2": "Ink Pen",
        "MaterialCategory": "Stationery",
        "UOM": "Num",
        "Quantity": "10",
        "AvailableQuantity": "10",
        "ReorderQuantity": "10",
        "PartialAcceptance": "10",
        "LineWiseAcceptance": ""
        
       
        
      },

      {
        "RequisitionsNo": "2",
        "MaterialDescription": "Laptop",
        "MaterialSubDescription1": "Touch Pad",
        "MaterialSubDescription2": "Dell",
        "MaterialCategory": "IT",
        "UOM": "Num",
        "Quantity": "20",
        "AvailableQuantity": "10",
        "ReorderQuantity": "10",
        "PartialAcceptance": "10",
        "LineWiseAcceptance": ""
        
      
        
      }
    ]
  }

}
