import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  Reset(passwordinput,confirmpasswordinput)
  {
    //this.router.navigateByUrl('/login')
    if(passwordinput.value!='' && confirmpasswordinput.value!='')
    {
      if(passwordinput.value == confirmpasswordinput.value)
      {
        alert('Password change successfully')
        this.router.navigateByUrl('/login')
      } 
      else
      {
        alert('Please enter correct password')
      }    
    }
    else
    {
    alert('Please enter password and confirm password')
    }  
  }

  Cancel()
  {
    this.router.navigateByUrl('/login')

  }
}
