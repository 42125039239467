import { Component, OnInit } from '@angular/core';


interface MaterialDescription {
  value: string;
  viewValue: string;
}

interface Materialsubdescription1 {
  value: string;
  viewValue: string;
}

interface Materialsubdescription2 {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-emp-req',
  templateUrl: './emp-req.component.html',
  styleUrls: ['./emp-req.component.css']
})
export class EmpReqComponent {

 

  txtMaterialCategory:boolean;

  constructor() { }
  public PurchaseRequesionData:any;
  ngOnInit(): void {

    this.txtMaterialCategory=true;

    this.PurchaseRequesionData = [
      {
         "RequisitionsNo": "1",
        "MaterialDescription": "Pen",
        "MaterialSubDescription1": "Gail Pen",
        "MaterialSubDescription2": "Ink Pen",
        "MaterialCategory": "Stationery",
        "UOM": "Num",
        "Quantity": "10",
        
        "Action": "",             
        
      },

      {
        "RequisitionsNo": "2",
        "MaterialDescription": "Laptop",
        "MaterialSubDescription1": "Touch Pad",
        "MaterialSubDescription2": "Dell",
        "MaterialCategory": "IT",
        "UOM": "Num",
        "Quantity": "20",
        
        "Action": "",  
               
      }
    ]

    


  }

  

  materialDescriptions: MaterialDescription[] = [
    {value: '0001', viewValue: 'Pen'},
    {value: '0002', viewValue: 'Laptop'},
    {value: '0003', viewValue: 'Computer'},
    {value: '0004', viewValue: 'Table'}
   
  ];

  materialsubdescription1: Materialsubdescription1[] = [
    {value: '0001', viewValue: 'Pen'},
    {value: '0002', viewValue: 'Laptop'},
    {value: '0003', viewValue: 'Computer'},
    {value: '0004', viewValue: 'Table'}
   
  ];
  materialsubdescription2: Materialsubdescription2[] = [
    {value: '0001', viewValue: 'Pen'},
    {value: '0002', viewValue: 'Laptop'},
    {value: '0003', viewValue: 'Computer'},
    {value: '0004', viewValue: 'Table'}
   
  ];
}
