import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-pass',
  templateUrl: './forgot-pass.component.html',
  styleUrls: ['./forgot-pass.component.css']
})
export class ForgotPassComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  
  Cancel()
  {
    this.router.navigateByUrl('/login')

  }

  SendOTP(emailinput)
  {
    //this.router.navigateByUrl('/sendotp')
    if(emailinput.value!='')
    {     
        this.router.navigateByUrl('/sendotp')      
    }
    else
    {
    alert('Please enter valid email ID')
    }   
  }
}
