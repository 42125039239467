import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
  
   { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
   { path: '/reg', title: 'Registration',  icon:'person', class: '' }, 
   { path: '/quotation', title: 'Quotation',  icon:'content_paste', class: '' }, 
   { path: '/dispatch', title: 'Dispatch',  icon:'bubble_chart', class: '' },
   { path: '/invoiceupload', title: 'Invoice Upload',  icon:'attach_file', class: '' },
   { path: '/purchaseorder', title: 'Purchase Order',  icon:'sync_problem', class: '' },
   { path: '/paymentstatus', title: 'Payment Status',  icon:'sync', class: '' },
   { path: '/emp-req', title: 'Create Requisitions',  icon:'sync', class: '' },
   { path: '/stockkeeper', title: 'Stock Keeper',  icon:'sync', class: '' },
   { path: '/purforprocurement', title: 'Purchase for Procurement',  icon:'sync', class: '' },
   { path: '/reqstatus', title: 'Requisitions Status',  icon:'sync', class: '' },
   { path: '/vendorstatus', title: 'Vendor Status',  icon:'sync', class: '' },
   { path: '/login', title: 'Logout',  icon:'logout', class: '' }
  //  { path: '/rfq', title: 'RFQ',  icon:'sync', class: '' },
  //  { path: '/po', title: 'PO',  icon:'sync', class: '' }
   //{ path: '/tabs', title: 'tabs',  icon:'unarchive', class: '' }, 
   //{ path: '/login', title: 'Login',  icon:'login', class: '' },
  //  { path: '/info', title: 'Info',  icon:'unarchive', class: '' },
   //{ path: '/register', title: 'Register',  icon:'unarchive', class: '' }
   //{ path: '/signup', title: 'Signup',  icon:'Signup', class: '' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
