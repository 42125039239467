import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reqstatus',
  templateUrl: './reqstatus.component.html',
  styleUrls: ['./reqstatus.component.css']
})
export class ReqstatusComponent implements OnInit {

  

  constructor() { }

  ngOnInit(): void {
  }

}
