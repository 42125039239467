import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';




@Component({
  selector: 'app-login',
  templateUrl: './login.html',
  styleUrls: ['./login.css']
})
export class Login implements OnInit{

  
  constructor(private router: Router) { }

  ngOnInit(): void {
    
    
  }

  dashboard(emailinput,passwordinput)
  {
    //alert(emailinput.value + passwordinput.value)
    //alert(passwordinput.value)

      if(emailinput.value=='vinod@gmail.com' && passwordinput.value=="123456")
      {
        this.router.navigateByUrl('/dashboard')
      }
      else
      {
      alert('Please enter valid email ID and password')
      }   
  }


 

 
  register()
  {
    //this.router.navigateByUrl('/register')
    this.router.navigateByUrl('/signup')
  }
  forgotpass()
  {
    this.router.navigateByUrl('/forgot-pass')

  }
}
