import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import {ThemePalette} from '@angular/material/core';
//import * as Chartist from 'chartist';
//import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, NgForm } from '@angular/forms';
//import { DocumentService } from './Document.service';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { constants } from 'buffer';

interface Food {
  value: string;
  viewValue: string;
}

interface Title {
  value: string;
  viewValue: string;
}
interface OrganisationType {
  value: string;
  viewValue: string;
}

interface AccountType{
  value:string;
  viewValue:string;
}

interface Code {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-reg',
  templateUrl: './reg.component.html',
  styleUrls: ['./reg.component.css']
})
export class RegComponent {
  


  //vinod added 13-4-2021
  jsonData:any = [];
  // public nameData: {tollPlazaId: string, tollName: string}[] = dummydata;
  codeList:any;
  data: any = [
    {
        "codeType": 1,
        "codeDesc": "India",
        "refCode": "IN",
        "modeFlag": null,
        "loginEmailId": null,
        "country": null,
        "pageSize": 0,
        "currentPage": 0,
        "mode": null
    },
    {
        "codeType": 2,
        "codeDesc": "Singapore",
        "refCode": "SG",
        "modeFlag": null,
        "loginEmailId": null,
        "country": null,
        "pageSize": 0,
        "currentPage": 0,
        "mode": null
    },
    {
        "codeType": 3,
        "codeDesc": "Thailand",
        "refCode": "TH",
        "modeFlag": null,
        "loginEmailId": null,
        "country": null,
        "pageSize": 0,
        "currentPage": 0,
        "mode": null
    },
    {
        "codeType": 4,
        "codeDesc": "China",
        "refCode": "CN",
        "modeFlag": null,
        "loginEmailId": null,
        "country": null,
        "pageSize": 0,
        "currentPage": 0,
        "mode": null
    },
    {
        "codeType": 5,
        "codeDesc": "Japan",
        "refCode": "JP",
        "modeFlag": null,
        "loginEmailId": null,
        "country": null,
        "pageSize": 0,
        "currentPage": 0,
        "mode": null
    },
    {
        "codeType": 6,
        "codeDesc": "Romania",
        "refCode": "RO",
        "modeFlag": null,
        "loginEmailId": null,
        "country": null,
        "pageSize": 0,
        "currentPage": 0,
        "mode": null
    },
    {
        "codeType": 7,
        "codeDesc": "Sweden",
        "refCode": "SE",
        "modeFlag": null,
        "loginEmailId": null,
        "country": null,
        "pageSize": 0,
        "currentPage": 0,
        "mode": null
    },
    {
        "codeType": 8,
        "codeDesc": "United Kingdom",
        "refCode": "GB",
        "modeFlag": null,
        "loginEmailId": null,
        "country": null,
        "pageSize": 0,
        "currentPage": 0,
        "mode": null
    },
    {
        "codeType": 9,
        "codeDesc": "France",
        "refCode": "FR",
        "modeFlag": null,
        "loginEmailId": null,
        "country": null,
        "pageSize": 0,
        "currentPage": 0,
        "mode": null
    },
    {
        "codeType": 10,
        "codeDesc": "Germany",
        "refCode": "DE",
        "modeFlag": null,
        "loginEmailId": null,
        "country": null,
        "pageSize": 0,
        "currentPage": 0,
        "mode": null
    },
    {
        "codeType": 11,
        "codeDesc": "USA",
        "refCode": "US",
        "modeFlag": null,
        "loginEmailId": null,
        "country": null,
        "pageSize": 0,
        "currentPage": 0,
        "mode": null
    },
    {
        "codeType": 12,
        "codeDesc": "Canada",
        "refCode": "CA",
        "modeFlag": null,
        "loginEmailId": null,
        "country": null,
        "pageSize": 0,
        "currentPage": 0,
        "mode": null
    },
    {
        "codeType": 13,
        "codeDesc": "Mexico",
        "refCode": "MX",
        "modeFlag": null,
        "loginEmailId": null,
        "country": null,
        "pageSize": 0,
        "currentPage": 0,
        "mode": null
    },
    {
        "codeType": 14,
        "codeDesc": "Italy",
        "refCode": "IT",
        "modeFlag": null,
        "loginEmailId": null,
        "country": null,
        "pageSize": 0,
        "currentPage": 0,
        "mode": null
    },
    {
        "codeType": 15,
        "codeDesc": "Netherlands",
        "refCode": "NL",
        "modeFlag": null,
        "loginEmailId": null,
        "country": null,
        "pageSize": 0,
        "currentPage": 0,
        "mode": null
    },
    {
        "codeType": 16,
        "codeDesc": "Ireland",
        "refCode": "IE",
        "modeFlag": null,
        "loginEmailId": null,
        "country": null,
        "pageSize": 0,
        "currentPage": 0,
        "mode": null
    }
];

  @ViewChild('scroll', { read: ElementRef }) public scroll: ElementRef<any>;


  
 btnPrintTCOC:boolean;
 btnSaveTCOC:boolean;
 btnSubmitTCOC:boolean;

 btnPrintNDA:boolean;
 btnSaveNDA:boolean;
 btnSubmitNDA:boolean;

 textGST:boolean;
 textPAN:boolean;

 textSalesTax:boolean;
 textTIN:boolean;

 textVAT:boolean;

 textISO90:boolean;
 textISO27:boolean;
 textISO91:boolean;

 textNameofComapanies:boolean;
 textTypeOfWorkDone:boolean;

 textSWIFTBICCode:boolean;
 textyear1:boolean;
 textyear2:boolean;
 textyear3:boolean;
 textProfit1INR:boolean;
 textProfit2INR:boolean;
 textProfit3INR:boolean;
 textTrunover1INR:boolean;
 textTrunover2INR:boolean;
 textTrunover3INR:boolean;



 CountryCode:string;

 divinfoIN:boolean;
 divinfoUS:boolean;
 divinfoGB:boolean;

 
 divbankIN:boolean;
 divbankUS:boolean;
 divbankGB:boolean;

 divFinancialIN:boolean;
 divFinancialUS:boolean;
 divFinancialGB:boolean;

 //vinod added 13-4-2021
 returnData: any;
  errorMessage: string;
  http: any;
  public codeValue: string;

//GST Compliant event
GSTCompliant(event){
  //Return True/False Check/UnCheck
  if (event.target.checked)
  {  
  this.textGST=false;
  this.textPAN=true;
  }
  else if (!event.target.checked)
  {
   this.textGST=true;
   this.textPAN=false;
  }
}

//SalesTax Compliant event
SalesTaxCompliant(event){
  //Return True/False Check/UnCheck
  if (event.target.checked)
  {  
  this.textSalesTax=false;
  //this.textTIN=false;
  }
  else if (!event.target.checked)
  {
   this.textSalesTax=true;
   //this.textTIN=false;
  }
}

//VAT Compliant event
VATCompliant(event){
  //Return True/False Check/UnCheck
  if (event.target.checked)
  {  
  this.textVAT=false;
  
  }
  else if (!event.target.checked)
  {
   this.textVAT=true;
   
  }
}

//ISO 9001:2008 event
ISO90Yes(event){
  //Return True/False Check/UnCheck
  if (event.target.checked)
  {  
  this.textISO90=true;
  }  
}
ISO90No(event){
  //Return True/False Check/UnCheck
  if (event.target.checked)
  {  
  this.textISO90=true;
  } 
}
ISO90Planned(event){
  //Return True/False Check/UnCheck
  if (event.target.checked)
  {  
  this.textISO90=false;
  }
}

//ISO 27001:2005
ISO27Yes(event){
  //Return True/False Check/UnCheck
  if (event.target.checked)
  {  
  this.textISO27=true;
  }  
}
ISO27No(event){
  //Return True/False Check/UnCheck
  if (event.target.checked)
  {  
  this.textISO27=true;
  } 
}
ISO27Planned(event){
  //Return True/False Check/UnCheck
  if (event.target.checked)
  {  
  this.textISO27=false;
  }
}

//9100 C
ISO91Yes(event){
  //Return True/False Check/UnCheck
  if (event.target.checked)
  {  
  this.textISO91=true;
  }  
}
ISO91No(event){
  //Return True/False Check/UnCheck
  if (event.target.checked)
  {  
  this.textISO91=true;
  } 
}
ISO91Planned(event){
  //Return True/False Check/UnCheck
  if (event.target.checked)
  {  
  this.textISO91=false;
  }
}

//vinod added 13-4-2021
GetSupplierCat()
{
 
this.ProductList().subscribe(
  (responseData) => {
   // this.loading = false;
    this.returnData = JSON.parse(JSON.stringify(responseData));
    if (this.returnData.status === 0) {
      alert(this.returnData.status)
        this.codeList=this.returnData
     // this.alerts.setMessage(this.returnData.message,'info');
      
    } else {
      alert(this.returnData.message);
         return;
       };
    },
    (error) => {
    //  this.loading = false;
      this.errorMessage = JSON.stringify(error);
     // this.alerts.setMessage(this.errorMessage,'error')
        alert(this.errorMessage);
      alert('Server is not Connected, Please Check Network');
       
        return;
     
      
    }
    );
  }
ProductList() {
  const httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json', 
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Methods': 'Post'
     
    })
  };
  let modeFlag: string="SC"
  let loginEmailId: string= ""
  let country: string=""
  let pageSize: 20
  let currentPage: 0
  let mode:string="R"
 // this.alerts.setMessage(environment.API_URL + environment.GetPlazaList_URL,'info');
  return this.http.post(environment.API_URL + environment.GetSupplierCat_URL,{modeFlag,loginEmailId,country,pageSize,currentPage,mode});
}

//vinod added 14-4-2021 Info Tab
GetCountry()
{
 // alert('ok')
 
this.CountryList("CO","","",20,0,"R").subscribe(
  (responseData) => {
    alert('in')
   // this.loading = false;
    this.returnData = JSON.parse(JSON.stringify(responseData));
    alert (this.returnData.status)
    if (this.returnData.status == 0) {
      alert(this.returnData.status)
        this.codeList=this.returnData.data
     // this.alerts.setMessage(this.returnData.message,'info');
      
    } else {
      alert(this.returnData.message);
         return;
       };
    },
    (error) => {
      //this.loading = false;
      this.errorMessage = JSON.stringify(error);
     // this.alerts.setMessage(this.errorMessage,'error')
        alert(this.errorMessage);
      alert('Server is not Connected, Please Check Network');
       
        return;
     
      
    }
    );
  }
CountryList(modeFlag: string,loginEmailId: string,country: string,pageSize:number,currentPage:number,mode:string) {
 // alert(environment.API_URL + environment.GetCountry_URL)
  // const httpOptions = {
  //   headers: new HttpHeaders({ 
  //     'Content-Type': 'application/json', 
  //     'Access-Control-Allow-Origin':'*',
  //     'Access-Control-Allow-Methods': 'Post'
     
  //   })
  // };
  const httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*'
     
     
     
    })
  };
  
  // let modeFlag: string="CO"
  // let loginEmailId: string= ""
  // let country: string=""
  // let pageSize:number= 20
  // let currentPage:number=0
  // let mode:string="R"
 // this.alerts.setMessage(environment.API_URL + environment.GetPlazaList_URL,'info');
//  "modeFlag": "CO",
//  "loginEmailId": "",
//  "country": "",
//  "pageSize": 0,
//  co"currentPage": 0,
//  "mode": "R"
// ,"loginEmailId":loginEmailId,
//   "country":country,
//   "pageSize":pageSize,
//   "currentPage":currentPage,
//   "mode":mode});
//return this.httpClient.get(environment.API_URL + environment.GetStateList_URL)

  return this.httpClient.post(environment.API_URL + environment.GetCountry_URL,{modeFlag
  ,loginEmailId,
  country,
  pageSize,
  currentPage,
  mode},httpOptions);
}

GetTitle()
{
 
this.TitleList().subscribe(
  (responseData) => {
   // this.loading = false;
    this.returnData = JSON.parse(JSON.stringify(responseData));
    
        this.codeList=this.returnData
     // this.alerts.setMessage(this.returnData.message,'info');
      
    
    },
    (error) => {
      //this.loading = false;
      this.errorMessage = JSON.stringify(error);
     // this.alerts.setMessage(this.errorMessage,'error')
        alert(this.errorMessage);
      alert('Server is not Connected, Please Check Network');
       
        return;
     
      
    }
    );
  }
  TitleList() {
  const httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json', 
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Methods': 'Post'
     
    })
  };

  let modeFlag: string="T"
  let loginEmailId: string= ""
  let country: string=""
  let pageSize: 20
  let currentPage: 0
  let mode:string="R"
 // this.alerts.setMessage(environment.API_URL + environment.GetPlazaList_URL,'info');
  return this.http.post(environment.API_URL + environment.GetTitle_URL,{modeFlag,loginEmailId,country,pageSize,currentPage,mode});
}

GetOrganizationType()
{
 
this.OrganizationTypeList().subscribe(
  (responseData) => {
   // this.loading = false;
    this.returnData = JSON.parse(JSON.stringify(responseData));
    
        this.codeList=this.returnData
     // this.alerts.setMessage(this.returnData.message,'info');
      
   
    },
    (error) => {
      //this.loading = false;
      this.errorMessage = JSON.stringify(error);
     // this.alerts.setMessage(this.errorMessage,'error')
        alert(this.errorMessage);
      alert('Server is not Connected, Please Check Network');
       
        return;
     
      
    }
    );
  }
  OrganizationTypeList() {
  const httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json', 
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Methods': 'Post'
     
    })
  };
  let modeFlag: string="OT"
  let loginEmailId: string= ""
  let country: string=""
  let pageSize: 20
  let currentPage: 0
  let mode:string="R"
 // this.alerts.setMessage(environment.API_URL + environment.GetPlazaList_URL,'info');
  return this.http.post(environment.API_URL + environment.GetOrganizationType_URL,{modeFlag,loginEmailId,country,pageSize,currentPage,mode});
}

GetRegion()
{
 
this.RegionList().subscribe(
  (responseData) => {
   // this.loading = false;
    this.returnData = JSON.parse(JSON.stringify(responseData));
   
        this.codeList=this.returnData
     // this.alerts.setMessage(this.returnData.message,'info');
      
   
    },
    (error) => {
      //this.loading = false;
      this.errorMessage = JSON.stringify(error);
     // this.alerts.setMessage(this.errorMessage,'error')
        alert(this.errorMessage);
      alert('Server is not Connected, Please Check Network');
       
        return;
     
      
    }
    );
  }
  RegionList() {
  const httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json', 
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Methods': 'Post'
     
    })
  };
  let modeFlag: string="R"
  let loginEmailId: string= ""
  let country: string="IN"
  let pageSize: 20
  let currentPage: 0
  let mode:string="R"
 // this.alerts.setMessage(environment.API_URL + environment.GetPlazaList_URL,'info');
  return this.http.post(environment.API_URL + environment.GetRegion_URL,{modeFlag,loginEmailId,country,pageSize,currentPage,mode});
}

//Bank tab
GetBankCountry()
{
 
this.BankCountryList().subscribe(
  (responseData) => {
   // this.loading = false;
    this.returnData = JSON.parse(JSON.stringify(responseData));
    
        this.codeList=this.returnData
     // this.alerts.setMessage(this.returnData.message,'info');
      
    
    },
    (error) => {
      //this.loading = false;
      this.errorMessage = JSON.stringify(error);
     // this.alerts.setMessage(this.errorMessage,'error')
        alert(this.errorMessage);
      alert('Server is not Connected, Please Check Network');
       
        return;
     
      
    }
    );
  }
BankCountryList() {
  const httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json', 
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Methods': 'Post'
     
    })
  };
  let modeFlag: string="BC"
  let loginEmailId: string= ""
  let country: string=""
  let pageSize: 20
  let currentPage: 0
  let mode:string="R"
 // this.alerts.setMessage(environment.API_URL + environment.GetPlazaList_URL,'info');
  return this.http.post(environment.API_URL + environment.GetBankCountry_URL,{modeFlag,loginEmailId,country,pageSize,currentPage,mode});
}

GetCurrency()
{
 
this.CurrencyList().subscribe(
  (responseData) => {
   // this.loading = false;
    this.returnData = JSON.parse(JSON.stringify(responseData));
    
        this.codeList=this.returnData
     // this.alerts.setMessage(this.returnData.message,'info');
      
   
    },
    (error) => {
      //this.loading = false;
      this.errorMessage = JSON.stringify(error);
     // this.alerts.setMessage(this.errorMessage,'error')
        alert(this.errorMessage);
      alert('Server is not Connected, Please Check Network');
       
        return;
     
      
    }
    );
  }
  CurrencyList() {
  const httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json', 
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Methods': 'Post'
     
    })
  };
  let modeFlag: string="CU"
  let loginEmailId: string= ""
  let country: string=""
  let pageSize: 20
  let currentPage: 0
  let mode:string="R"
 // this.alerts.setMessage(environment.API_URL + environment.GetPlazaList_URL,'info');
  return this.http.post(environment.API_URL + environment.GetCurrency_URL,{modeFlag,loginEmailId,country,pageSize,currentPage,mode});
}


GetAccountType()
{
 
this.AccountTypeList().subscribe(
  (responseData) => {
   // this.loading = false;
    this.returnData = JSON.parse(JSON.stringify(responseData));
   
        this.codeList=this.returnData
     // this.alerts.setMessage(this.returnData.message,'info');
      
  
    },
    (error) => {
      //this.loading = false;
      this.errorMessage = JSON.stringify(error);
     // this.alerts.setMessage(this.errorMessage,'error')
        alert(this.errorMessage);
      alert('Server is not Connected, Please Check Network');
       
        return;
     
      
    }
    );
  }
  AccountTypeList() {
  const httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json', 
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Methods': 'Post'
     
    })
  };
  let modeFlag: string="AT"
  let loginEmailId: string= ""
  let country: string=""
  let pageSize: 20
  let currentPage: 0
  let mode:string="R"
 // this.alerts.setMessage(environment.API_URL + environment.GetPlazaList_URL,'info');
  return this.http.post(environment.API_URL + environment.GetAccountType_URL,{modeFlag,loginEmailId,country,pageSize,currentPage,mode});
}

//Financial Tab
GetMinorityIndicator()
{
 
this.MinorityIndicatorList().subscribe(
  (responseData) => {
   // this.loading = false;
    this.returnData = JSON.parse(JSON.stringify(responseData));
    if (this.returnData.status === '1') {
        this.codeList=this.returnData.data
     // this.alerts.setMessage(this.returnData.message,'info');
      
    } else {
     alert(this.returnData.message);
        return;
      };
    },
    (error) => {
      //this.loading = false;
      this.errorMessage = JSON.stringify(error);
     // this.alerts.setMessage(this.errorMessage,'error')
        alert(this.errorMessage);
      alert('Server is not Connected, Please Check Network');
       
        return;
     
      
    }
    );
  }
  MinorityIndicatorList() {
  const httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json', 
      'Access-Control-Allow-Origin':'*',
      'Access-Control-Allow-Methods': 'Get'
     
    })
  };
 // this.alerts.setMessage(environment.API_URL + environment.GetPlazaList_URL,'info');
  return this.http.get(environment.API_URL + environment.GetMinorityIndicator_URL);
}



//Supply Any Other Tata Group Company event
SupplyAnyOtherTataGroupCompany(event){
  //Return True/False Check/UnCheck
  if (event.target.checked)
  {  
  this.textNameofComapanies=false;
  this.textTypeOfWorkDone=false;
  }
  else if (!event.target.checked)
  {
   
   this.textNameofComapanies=true;
   this.textTypeOfWorkDone=true;
  }
}
  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;

  accountType: AccountType[] = [
    {value: '0', viewValue: 'Current'},
    {value: '1', viewValue: 'Saving'}
    
  ];
  foods: Food[] = [
    {value: 'steak-0', viewValue: 'Steak'},
    {value: 'pizza-1', viewValue: 'Pizza'},
    {value: 'tacos-2', viewValue: 'Tacos'}
  ];

  titles: Title[] = [
    {value: '0001', viewValue: 'Ms'},
    {value: '0002', viewValue: 'Mr'},
    {value: '0003', viewValue: 'Company'},
    {value: '0004', viewValue: 'Mr. and Mrs.'},
    {value: '01', viewValue: 'Ms'},
    {value: '02', viewValue: 'Mr'},
    {value: '03', viewValue: 'Ms'},
    {value: '04', viewValue: 'Mrs'},
    {value: '05', viewValue: 'Miss'}
  ];

  organisationTypes: OrganisationType[] = [
    {value: '1', viewValue: 'Public Company'},
    {value: '2', viewValue: 'Private Company'},
    {value: '3', viewValue: 'Proprietorship'}
  ];

  code: Code[] = [
    {value: '0', viewValue: '+91'},
    {value: '1', viewValue: '+1'},
    {value: '2', viewValue: '+2'}
  ];

  public documentGrp: FormGroup;
   public totalfiles: Array<File> =[];
   public totalFileName = [];
   public lengthCheckToaddMore =0;

  
   
  constructor(private formBuilder: FormBuilder,private httpClient: HttpClient, ) { }

  public onPreviousSearchPosition() {
    this.scroll.nativeElement.scrollTop -= 20;
    alert("Agreed")
     //this.disablePrintBtn=true;
  }

  public onPrint() {
    
    alert("ok")
    
  }

   //NDA tab
   eventCheckNDA(event){
    //Return True/False Check/UnCheck
    if (event.target.checked)
    {
    this.btnPrintNDA=false;
    this.btnSaveNDA=false;
    this.btnSubmitNDA=false;
    }
    else if (!event.target.checked)
    {  
      this.btnPrintNDA=true;
      this.btnSaveNDA=true;
      this.btnSubmitNDA=true;
    }
  }
  //TCOC tab
  eventCheckTCOC(event){
    //Return True/False Check/UnCheck
    if (event.target.checked)
    {
    this.btnPrintTCOC=false;
    this.btnSaveTCOC=false;
    this.btnSubmitTCOC=false;
    }
    else if (!event.target.checked)
    {  
      this.btnPrintTCOC=true;
      this.btnSaveTCOC=true;
      this.btnSubmitTCOC=true;
    }
  }
  public RefData:any;
  ngOnInit() {  
    this.codeValue='Easyst Sourcing'
    //this.fName='test';
    //this.codeList=this.data;
   this.GetCountry();

    this.btnPrintTCOC=true;
    this.btnSaveTCOC=true;
    this.btnSubmitTCOC=true;

    this.btnPrintNDA=true;
    this.btnSaveNDA=true;
    this.btnSubmitNDA=true;

    this.textGST=true;
    this.textPAN=false;

    this.textSalesTax=true;
    //this.textTIN=false;

    this.textVAT=true;

    this.textISO90=true;
    this.textISO27=true;
    this.textISO91=true;

    this.textNameofComapanies=true;
    this.textTypeOfWorkDone=true;

    this.textSWIFTBICCode=true;
    this.textyear1=true;
    this.textyear2=true;
    this.textyear3=true;
    this.textProfit1INR=true;
    this.textProfit2INR=true;
    this.textProfit3INR=true;
    this.textTrunover1INR=true;
    this.textTrunover2INR=true;
    this.textTrunover3INR=true;

    this.CountryCode='IN';

    if(this.CountryCode=='IN')
    {
      //alert('ok IN')
      this.divinfoIN=true;
      this.divinfoUS=false;
      this.divinfoGB=false;
  
      this.divbankIN=true;
      this.divbankUS=false;
      this.divbankGB=false;

      this.divFinancialIN=true;
      this.divFinancialUS=false;
      this.divFinancialGB=false;
    }
    else if(this.CountryCode=='US')
    {
      //alert('ok US')
      this.divinfoIN=false;
      this.divinfoUS=true;
      this.divinfoGB=false;
  
      this.divbankIN=false;
      this.divbankUS=true;
      this.divbankGB=false;

      this.divFinancialIN=false;
      this.divFinancialUS=true;
      this.divFinancialGB=false;
    }
    else if(this.CountryCode=='GB')
    {
      //alert('ok GB')
      this.divinfoIN=false;
      this.divinfoUS=false;
      this.divinfoGB=true;
  
      this.divbankIN=false;
      this.divbankUS=false;
      this.divbankGB=true;

      this.divFinancialIN=false;
      this.divFinancialUS=false;
      this.divFinancialGB=true;
    }
    

   //Document tab
   this.documentGrp = this.formBuilder.group({
    doc_name: '',
    doc_description: '',
    doc_date:'',
    documentFile:new FormControl(File),

    items: this.formBuilder.array([this.createUploadDocuments()])
  });

  this.addItem();


  this.RefData = [
    {    
      "CustomerName": "rahul deore",
      "ContactPerson": "amol",
      "DesignationTitle": "Admin",
      "ContactNo": "+91 7857566565",
      "EmailID": "rahul@gmail.com"     
    }
  ]
 }

 Save(){
   alert('call')
   this.GetCountry();
 }

public onNextSearchPosition(){
  this.scroll.nativeElement.scrollTop += 20;
   console.log('down');
}


createUploadDocuments(): FormGroup {
  return this.formBuilder.group({
    doc_name: '',
    doc_description: '',
    doc_date:'',
    documentFile : File
  });
}

get items(): FormArray {
  return this.documentGrp.get('items') as FormArray;
};

addItem(): void {


//console.log("length is ",this.totalfiles.length);
//console.log("lengthCheckToaddMore ", this.lengthCheckToaddMore);

//if(this.totalfiles.length!=0)
// if( this.items.value[0].doc_name != "" && this.items.value[0].doc_description != "" && ((this.lengthCheckToaddMore) === (this.totalfiles.length)) )
// {
for (let index = 0; index < 5; index++)  
{  
  this.items.insert(0, this.createUploadDocuments())
   this.lengthCheckToaddMore=this.lengthCheckToaddMore+1;
}
//}
}

removeItem(index: number) {

 this.totalfiles.splice(index);
 this.totalFileName.splice(index);
  this.items.removeAt(index);
  this.lengthCheckToaddMore=this.lengthCheckToaddMore-1;
 // console.log("name are ",this.totalFileName);
  
}

public fileSelectionEvent(fileInput: any,oldIndex) {

  console.log("newIndex is ", oldIndex);
  
  if (fileInput.target.files && fileInput.target.files[0]) {
    var reader = new FileReader();
    reader.onload = (event: any) => {
    }
    if(oldIndex==0)
  {
    this.totalfiles.unshift((fileInput.target.files[0]))
    this.totalFileName.unshift(fileInput.target.files[0].name)
  }
  else
  {
    this.totalfiles[oldIndex]=(fileInput.target.files[0]);
    this.totalFileName[oldIndex]=fileInput.target.files[0].name
  }
 
    reader.readAsDataURL(fileInput.target.files[0]);
  }

  if(this.totalfiles.length == 1)
  {
    this.lengthCheckToaddMore=1;
  }

}




public OnSubmit(formValue: any) {


  let main_form: FormData = new FormData();

  for(let j=0;j<this.totalfiles.length; j++)
  {
    console.log("the values is ",<File>this.totalfiles[j]);
    console.log("the name is ",this.totalFileName[j]);
    
    main_form.append(this.totalFileName[j],<File>this.totalfiles[j])
  }
  console.log(formValue.items)
 
  

  //reverseFileNames=this.totalFileName.reverse();
 
  let AllFilesObj= []

  formValue.items.forEach((element, index) => { 
   
    console.log("index is ",index);
    console.log("element is ", element);
    
    let eachObj=
    {
      'doc_name' : element.doc_name,
      'doc_description' : element.doc_description,
      'file_name' : this.totalFileName[index]
    }
    AllFilesObj.push(eachObj); 
  });

  //console.log("the Array data is ",AllFilesObj);
  main_form.append("fileInfo",JSON.stringify(AllFilesObj))

  // this.DocumentService.saveFiles(main_form).subscribe(data => {
  //   alert(data)
  //   console.log("result is ", data)
  // })
}
}





