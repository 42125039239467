import { Component} from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  //templateUrl:'<router-outlet></router-outlet>',
  styleUrls: ['./app.component.css']
  //styleUrls:[]
})
export class AppComponent {

}
