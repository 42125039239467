import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-vendorstatus',
  templateUrl: './vendorstatus.component.html',
  styleUrls: ['./vendorstatus.component.css']
})

  export class VendorstatusComponent implements OnInit {

  

    constructor() { }
  
    ngOnInit(): void {
    }
  
  }
   

 

 
