import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sendotp',
  templateUrl: './sendotp.component.html',
  styleUrls: ['./sendotp.component.scss']
})
export class SendotpComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }


  Next(OTPinput)
  {
    //this.router.navigateByUrl('/reset')
    if(OTPinput.value!='')
    {
      if(OTPinput.value=='123456')
      {
      this.router.navigateByUrl('/reset')
      }
      else
      {
      alert('Please enter valid OTP')
      } 
    }
    else
    {
    alert('Please enter valid OTP')
    }  
  }

  Cancel()
  {
    this.router.navigateByUrl('/login')

  }
}
